import React, { useState, useEffect, Controller } from 'react';
import { Button } from '@mui/material';
import {
  Grid,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Modal,
  Divider,
  Snackbar,
  CircularProgress,
} from '@mui/material';
import { CustomTextField } from 'components';
import { useForm } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@material-ui/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useWatch } from 'react-hook-form';
import { formatMobileNumber } from '../../utils/phone';
import { useContactUs } from '../../query/contactUs';
import { ResponseStatusPage } from '../SMRRInfo/PaymentMethod/ResponseStatusPage';
import done from '../../utils/gif/done.json';

const formSchemea = yup.object().shape({
  first_name: yup
    .string()
    .nullable()
    // .transform((value, originalValue) => (originalValue.trim() === '' ? null : value))
    .test(
      'not-numeric',
      'First Name should not be a numeric value',
      (value) => {
        if (value === null || value === '') return true;

        return isNaN(Number(value));
      }
    )
    .test('min characters', 'Minimum 2 characters required', (value) => {
      if (value === null || value === '') return true;
      return value.length >= 2;
    })
    .test('max characters', 'Maximum 25 characters are allowed', (value) => {
      if (value === null || value === '') return true;
      return value.length <= 25;
    }),

  last_name: yup
    .string()
    .nullable()
    .test('min characters', 'Minimum 2 characters required', (value) => {
      if (value === null || value === '') return true;
      return value.length >= 2;
    })
    .test('max characters', 'Maximum 25 characters are allowed', (value) => {
      if (value === null || value === '') return true;
      return value.length <= 25;
    }),

  email: yup
    .string()
    .required('Email is required')
    .email('Please enter a valid email'),
  // privacy_concern: yup.string().required('Kindly acknowledge'),
});

const Content = (props) => {
  //styles
  const errorMsg = {
    fontSize: '12px',
    color: '#ff0000',
  };
  const customLabel = {
    fontSize: '16px',
    fontWeight: '400',
    color: '#757575',
  };
  const underline = {
    border: '1px solid',
    borderBottomColor: '#757575',
    borderTopColor: '#FFFFFF',
    borderLeftColor: '#FFFFFF',
    borderRightColor: '#FFFFFF',
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(formSchemea) });
  const phone = useWatch({ control, name: 'phone_number' });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isOpen, setIsopen] = useState(false);
  const {
    mutate: submit,
    refetch: refetchContacts,
    isLoading: isLoadingContact,
  } = useContactUs();

  const [isPrivacyCheked, setIsPrivacyCheked] = useState(false);
  // const linkFromAPI = privacyPolicy?.useGetPrivacyData;

  const checkPrivacy = (e) => {
    if (e?.target?.checked) {
      setIsPrivacyCheked(true);
    } else {
      setIsPrivacyCheked(false);
    }
  };


  const onClose = () => {
    setIsopen(false);
  };

  const onClickButton = (formValues) => {
    const data = {
      first_name: formValues?.first_name,
      last_name: formValues?.last_name,
      email: formValues?.email,
      phone: formValues?.phone_number,
      query: formValues?.message,
    };


    submit(data, {
      onSuccess: (res) => {
        setIsopen(true);
        reset();
        // refetch();
      },
      onError: (err) => {
        setSnackbarOpen(true);
      },
    });
  };
  //open privacy-policy
  const handleOpenLink = () => {

    // try {
    if (props?.privacydata) {
      // const linkFromAPI = data?.useGetPrivacyData;
      // Open the link in a new tab
      window.open(props?.privacydata?.data[0]?.privacy_policy, '_blank');
    }
    // }
    // catch (error) {
    // Handle any errors that occur during the API call
    // console.error("API call error:", error);
    // }
  };

  return (
    <Box padding={3} className='formMb'>
      <form onSubmit={handleSubmit(onClickButton)}>
        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          rowGap={2}
          style={{
            boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.08)',
            borderRadius: '8px',
            width: '100%',
            height: '100%',
          }}
          justifyContent="center"
          padding={4}
        >
          <Grid item container lg={12} md={12} sm={12} xs={12} rowGap={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography
                style={{
                  fontSize: '36px',
                  fontWeight: '600',
                  color: '#03131D',
                }}
              >
                Elevate Your Experience with Sell My Rig
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography
                style={{
                  fontSize: '20px',
                  fontWeight: '400',
                  color: '#545454',
                }}
              >
                We're here to connect, whether you have questions, seek partnerships, or simply want to engage in a modern conversation.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            lg={12}
            md={12}
            sm={12}
            xs={12}
            justifyContent="center"
          >
            <Grid item lg={6} md={6} sm={6} xs={6} className='fieldMb'>
              <CustomTextField
                style={{ isborder: false, boxShadow: 'rgba(0, 0, 0, 0.05)' }}
                label="First Name"
                fontSize="16px"
                type="text"
                fontWeight="500"
                color="#333333"
                placeholder="John"
                className="w-[100%] md:w-[95%]"
                FormHelperTextProps={{
                  className: 'animateErrorValue ',
                }}
                {...register('first_name')}
              />
              {errors.first_name && (
                <span style={errorMsg}>{errors.first_name.message}</span>
              )}
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6} justifyContent="center" className='fieldMb'>
              <CustomTextField
                style={{ isborder: false, boxShadow: 'rgba(0, 0, 0, 0.05)' }}
                label="Last Name"
                fontSize="16px"
                type="text"
                fontWeight="500"
                color="#333333"
                placeholder="Doe"
                className="w-[100%] md:w-[97%]"
                FormHelperTextProps={{
                  className: 'animateErrorValue ',
                }}
                {...register('last_name')}
              />
              {errors.last_name && (
                <span style={errorMsg}>{errors.last_name.message}</span>
              )}
            </Grid>
          </Grid>
          <Grid Item lg={12} md={12} sm={12} xs={12} className='fieldMb'>
            <CustomTextField
              style={{ isborder: false, boxShadow: 'rgba(0, 0, 0, 0.05)' }}
              label="Email"
              type="text"
              fontWeight="500"
              color="#333333"
              fontSize="16px"
              placeholder="you@example.com"
              className="w-[100%]"
              FormHelperTextProps={{
                className: 'animateErrorValue ',
              }}
              {...register('email')}
            />
            {errors.email && (
              <span style={errorMsg}>{errors.email.message}</span>
            )}
          </Grid>

          <Grid Item lg={12} md={12} sm={12} xs={12} className='w-[100%]'>
            <CustomTextField
              style={{ isborder: false, boxShadow: 'rgba(0, 0, 0, 0.05)' }}
              label="Phone Number"
              startAdornment={<Typography>+1</Typography>}
              type="text"
              fontSize="16px"
              fontWeight="500"
              color="#333333"
              placeholder="(555) 000-0000"
              className="w-[100%]"
              value={formatMobileNumber(phone, true)}
              FormHelperTextProps={{
                className: 'animateErrorValue ',
              }}
              {...register('phone_number')}
            />
            {errors.phone_number && (
              <span style={errorMsg}>{errors.phone_number.message}</span>
            )}
          </Grid>

          <Grid Item lg={12} md={12} sm={12} xs={12} className='fieldMb'>
            <CustomTextField
              style={{ isborder: false, boxShadow: 'rgba(0, 0, 0, 0.05)' }}
              label="Message"
              type="text"
              fontSize="16px"
              isMultiline="yes"
              fontWeight="500"
              color="#333333"
              minrows="4"
              placeholder=""
              className="w-[100%]"
              {...register('message')}
              FormHelperTextProps={{
                className: 'animateErrorValue ',
              }}
            />
            {errors.message && (
              <span style={errorMsg}>{errors.message.message}</span>
            )}
          </Grid>
          <Grid item container lg={12} md={12} sm={12} xs={12}>
            <Grid item lg={1} md={1} sm={1} xs={2}>
              <FormControlLabel
                name="privacy_concern"
                // style={{ paddingTop:"-5px" }}
                // control={handleClick}
                control={<Checkbox onClick={checkPrivacy} />}
              />
            </Grid>
            <Grid
              item
              lg={8}
              md={8}
              sm={8}
              xs={9}
              paddingTop={1}
              marginLeft="-5px"
            >
              <Typography style={customLabel}>
                You agree to our friendly{' '}
                <span
                  style={{
                    ...customLabel,
                    ...underline,
                    cursor: 'pointer',
                    textDecoration: 'none',
                  }}
                  onClick={handleOpenLink}
                >
                  privacy policy
                </span>
                .
              </Typography>
            </Grid>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            {isLoadingContact ? (
              <CustomButton>
                <CircularProgress size={28} className="!text-white" />{' '}
              </CustomButton>
            ) : (
              <Button
                type="submit"
                style={{
                  width: '97%',
                  color: '#FFFFFF',
                  backgroundColor: '#0F5E91',
                  fontSize: '16px',
                  fontWeight: '500',
                  disableRipple: true,
                }}
                className='formBtn'
                disabled={!isPrivacyCheked}
              >
                Send message
              </Button>
            )}
          </Grid>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={2000}
            onClose={() => setSnackbarOpen(false)}
            message="An error ocurred!"
          />
        </Grid>
      </form>
      <ResponseStatusPage
        isOpen={isOpen}
        first_name="Your request has been submitted successfully"
        second_message="Our team member will contact you shortly"
        isContactUsPage={true}
        animation={done}
        onClose={setIsopen}
      />
    </Box>
  );
};

const CustomButton = ({ children }) => {
  // console.log(styles);
  return (
    <Button
      style={{
        width: '97%',
        color: '#FFFFFF',
        backgroundColor: '#0F5E91',
        fontSize: '16px',
        fontWeight: '500',
        disableRipple: true,
      }}
    >
      {children}
    </Button>
  );
};

export default Content;
