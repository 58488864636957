import React, { useEffect, useState } from 'react';
import { Button, Grid, Link, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Box } from '@material-ui/core';

import './ProductSell.css';
// import Navbar from '../commonComponents/Navbar';
import VerticalLinearStepper from './stepper';
// Steps Components - Content
import { TruckCategory } from './ProductSellSteps/TruckCategory';
import { VehicleID } from './ProductSellSteps/VehicleID';
import { BasicInformationPS } from './ProductSellSteps/BasicInformation';
import { UploadImagesPS } from './ProductSellSteps/UploadImages';
import { UploadVideoPS } from './ProductSellSteps/UploadVideo';
import { DescriptionPS } from './ProductSellSteps/Description';
import { SpecificationSheetPS } from './ProductSellSteps/SpecificationSheet';
import { RepairHistoryPS } from './ProductSellSteps/RepairHistory';
import { ProductSellSchema } from './ProductSellSchema';
import APIResponceMsgModal from 'components/APIResponceMsgModal';
// Yup
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { axios } from '../../utils';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getLocalStorageItem, setLocalStorageItem } from '../../hooks';
import { toast } from 'react-toastify';
import { useGetLocationAddress } from '../../query/Home';




const ProductSell = ({...props}) => {

  const [openResponse, setOpenResponse] = useState(false);
  const [modelData, setModelData] = useState({});
  const [addressData, setAddressData] = useState(
    {
      zip_code: '',
      country: '',
      state: '',
      city: '',
      street: ''
    });

  const navigate = useNavigate();
  // JS - Definitions outside the return statement
  // const data={...props}
  const autoFilledData={name:"",};
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm({
    resolver: yupResolver(ProductSellSchema), defaultValues: autoFilledData
  });


  const [longitude, setLongitude] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const { data: locationData, refetch, dataUpdatedAt } = useGetLocationAddress({
    latitude,
    longitude,
  });

  const getMakeString = () => {
    let ddData = [];
    let finalVal = "";
    if(vehicle.make){
      basicInfoFormElements.forEach((item) => {
        if(Array.isArray(item)){
          item.forEach(obj => {
            if(obj.apiKey === 'make') {
              ddData = [...obj.data];
              ddData.forEach(ddItem => {
                if(ddItem.id == vehicle.make) {
                  finalVal = ddItem.name;
                }
              });
            }
          });
        } else {
          if (item.apiKey === 'make') {
            ddData = [...item.data];
            ddData.forEach(ddItem => {
              if (ddItem.id == vehicle.make) {
                finalVal = ddItem.name;
              }
            });
          }
        }
      });
    }
    return vehicle.make_text ? vehicle.make_text : finalVal;
  }

  const getModelString = () => {
    let ddData = [];
    let finalVal = "";
    if(vehicle.model){
      basicInfoFormElements.forEach((item) => {
        if(Array.isArray(item)){
          item.forEach(obj => {
            if(obj.apiKey === 'model') {
              ddData = [...obj.data];
              ddData.forEach(ddItem => {
                if(ddItem.id == vehicle.model) {
                  finalVal = ddItem.display_name;
                }
              });
            }
          });
        } else {
          if (item.apiKey === 'model') {
            ddData = [...item.data];
            ddData.forEach(ddItem => {
              if (ddItem.id == vehicle.model) {
                finalVal = ddItem.name;
              }
            });
          }
        }
      });
    }
    return vehicle.model_text ? vehicle.model_text : finalVal;
  }

  const prepareAttachmentArr = () => {
    let arr = [];
    // imagesMap, videosMap, specificationSheetMap, repairHistoryMap
    imagesMap.forEach(image => {
      if(image.id) {
        arr.push(image.id);
      }
    });
    videosMap.forEach(video => {
      if(video && video.id) {
        arr.push(video.id);
      }
    });
    specificationSheetMap.forEach(specificationSheet => {
      if(specificationSheet.id) {
        arr.push(specificationSheet.id);
      }
    });
    repairHistoryMap.forEach(repairHistory => {
      if(repairHistory.id) {
        arr.push(repairHistory.id);
      }
    })
    return arr;
  }

  const submitSellForm = () => {
    const VehicleKeys = [
      'mileage',
      'hour',
      'collision',
      'bed_size',
      'scale',
      'side_skirt',
      'air_ride',
      'color',
      'year',
      'make',
      'model',
      'engine_manufacturer',
      'trailer_manufacturer',
      'horsepower',
      'transmission_manufacturer',
      'distance',
      'gear_ratio',
      'gear_type',
      'apu',
      'axle_ratio',
      'front_tyre_brand',
      'front_tyre_size',
      'rear_tyre_brand',
      'rear_tyre_size',
      'vehicle_price',
      'brake_type',
      'inverter',
      'wheel_base',
      'tire_treads',
      'collision',
      'bed_count',
      'gross_vehicle_weight_rating',
    ];
    // Prepare Payload
    let data = {};
    data.category = category;
    data.sub_category = sub_category;
    let veh = {
      ...vehicle,
      mileage: vehicle.mileage !== '' ? vehicle.mileage : null,
      // transmission_manufacturer: "string",
      distance: 2147483647,
      type_of_fuel: 'Diesel',
      // bed_count: "string"
    };
    let potentialIssue = [];
    Object.keys(veh).forEach((key) => {
      //  || VehicleKeys.indexOf(key) === -1
      if (key.includes('_text') && veh[key] != '') {
        potentialIssue.push(key);
        veh[key.replace('_text', '')] = veh[key];
      }
      if (key.includes('_text')) {
        delete veh[key];
      }
    });
    // Override And Test

    veh.vehicle_price = veh.vehicle_price.replaceAll(',', '');
    data.vehicle = veh;
    data.description = desc;
    data.selling_price = veh.vehicle_price;
    data.type_of_fuel = 'Diesel';
    data.title = `${data.vehicle.year} ${getMakeString()} ${getModelString()}`;

    // Address data

    if (
      latitude &&
      longitude &&
      addressData.city &&
      addressData.country &&
      addressData.state &&
      addressData.zip_code &&
      addressData.street
    ) {
      data.street_address = addressData.street;
      data.city = addressData.city;
      data.state = addressData.state;
      data.country = addressData.country;
      data.zip_code = addressData.zip_code;
      data.latitude = latitude;
      data.longitude = longitude;
    }

    data.attachment = prepareAttachmentArr();

    const method = 'POST';
    const url = `listing/`;
    return axios({
      method,
      url,
      data,
    });
  };

  const { mutate: sellForm, isLoading } = useMutation(submitSellForm, {
    onSuccess: (res) => {
      //console.log(res, 'submitSellFormMutation Success');
      // Navigate to the success page
           
      //navigate("/success");
      setOpenResponse(true);
      setModelData({
        type:'success', 
        first_message: 'Request has been sent successfully', 
        second_message: `Thank you, Support team will contact you`
      }); 
    },
    onError: (err) => {
      console.log(err, 'submitSellFormMutation Error');
    }
  });

  const handleFinalSubmit = () => {
    // Submit the form
    sellForm();
  }


  const theme = useTheme();
  const isXLarge = useMediaQuery(theme.breakpoints.down('xl'));
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));

  // State/Data for Step 1
  const [category, setCategory] = useState();
  const [sub_category, setSub_category] = useState();

  const [truckList, setTruckList] = useState({
    'Commercial Trucks': [
      {
        name: 'Day Cab',
        img: './assets/Trucks/Commercial Trucks/daycab.svg',
        isActive: false
      },
      {
        name: 'Sleeper Cab',
        img: './assets/Trucks/Commercial Trucks/sleepercab.svg',
        isActive: false
      },
      {
        name: 'Dump Trucks',
        img: './assets/Trucks/Commercial Trucks/dumptrucks.svg',
        isActive: false
      },
      {
        name: 'Box Trucks',
        img: './assets/Trucks/Commercial Trucks/boxtrucks.svg',
        isActive: false
      },
    ],
    'Trailer': [
      {
        name: 'Reefer Trailer',
        img: './assets/Trucks/Trailer/reefertrailer.svg',
        isActive: false
      },
      {
        name: 'Tank Trailers',
        img: './assets/Trucks/Trailer/tanktrailers.svg',
        isActive: false
      },
      {
        name: 'Specialty Trucks',
        img: './assets/Trucks/Trailer/specialitytrucks.svg',
        isActive: false
      },
      {
        name: 'Flats Trucks',
        img: './assets/Trucks/Trailer/flattrucks.svg',
        isActive: true
      },
    ],
    'Van': [
      {
        name: 'Dry Van',
        img: './assets/Trucks/Van/dryvan.svg',
        isActive: false
      }
    ]
  });

  const [vehicle, setVehicle] = useState({});
  // State/Data forStep 2 - VIN Details

  // State/Data forStep 3 - Basic Information
  // Types of FormElements 'select' | 'text' | 'optional' | 'currency' | 'whitespace'
  const [basicInfoFormElements, setBasicInfoFormElements] = useState([
    [
      {
        type: 'optional',
        label: 'Brand',
        data: [],
        apiKey: 'make',
        validation: {required: 'Brand is mandatory'},
        renderIfCategoryIs: ''
      }
    ],
    [
      {
        type: 'optional',
        label: 'Model',
        data: [],
        apiKey: 'model',
        validation: {required: 'Model is mandatory'},
        renderIfCategoryIs: ''
      }
    ],
    [
      {
        type: 'optional',
        label: 'Trailer Length',
        data: [{}],
        apiKey: 'trailer_length',
        validation: {required: 'Trailer Length is mandatory'},
        renderIfCategoryIs: [9, 10]
      },
    ],
    [
      {
        type: 'optional',
        label: 'Color',
        data: null,
        apiKey: 'color',
        validation: {required: 'Color is mandatory'},
        renderIfCategoryIs: ''
      }
    ],
    [
      {
        type: 'optional',
        label: 'Bed Size',
        data: [{}],
        apiKey: 'bed_size',
        validation: { required: 'Bed Size is mandatory' },
        renderIfCategoryIs: [8]
      }
    ],
    [
      {
        type: 'optional',
        label: 'Wheel Base',
        data: [{}],
        apiKey: 'wheel_base',
        validation: {},
        renderIfCategoryIs: [8]
      }
    ],
    [
      {
        type: 'select',
        label: 'Year',
        data: [{ name: '2023', id: '2023' }, { displayName: '2022', id: '2022' }],
        apiKey: 'year',
        validation: {required: 'Year is mandatory'},
        renderIfCategoryIs: ''
      },
      {
        type: 'select',
        label: 'Rim Type',
        data: [{name: 'Steel', id: 'Steel'}, {name: 'Aluminium', id: 'Aluminium'}],
        apiKey: 'rim_type',
        validation: {required: 'Rim Type is mandatory'},
        renderIfCategoryIs: [9, 10]
      },
      {
        type: 'text',
        label: 'Hours',
        data: null,
        apiKey: 'hour',
        validationType: 'number',
        validation: {required: 'Hours is mandatory'},
        renderIfCategoryIs: [9, 10]
      },
      {
        type: 'select',
        label: 'E Tracks',
        data: [{ name: 'YES', id: 'YES' }, { name: 'NO', id: 'NO' }],
        apiKey: 'e_tracks',
        validation: {required: 'E Tracks is mandatory'},
        renderIfCategoryIs: [9, 10]
      },
      {
        type: 'select',
        label: 'Rub Rails',
        data: [{ name: 'YES', id: 'YES' }, { name: 'NO', id: 'NO' }],
        apiKey: 'rub_rails',
        validation: {required: 'Rub Rails is mandatory'},
        renderIfCategoryIs: [9, 10]
      },
      {
        type: 'select',
        label: 'Engine Manufacturer',
        data: [],
        apiKey: 'engine_manufacturer',
        validation: {required: 'Engine Manufacturer is mandatory'},
        renderIfCategoryIs: [8]
      },
      {
        type: 'select',
        label: 'Transmission Manufacturer',
        data: [],
        apiKey: 'transmission_manufacturer',
        validation: {required: 'Transmission Manufacturer is mandatory'},
        renderIfCategoryIs: [8]
      },
      {
        type: 'select',
        label: 'Gear Type',
        data: [{ name: 'Automatic', id: 'Automatic' }, { displayName: 'Manual', id: 'Manual' }],
        apiKey: 'gear_type',
        validation: {required: 'Gear Type is mandatory'},
        renderIfCategoryIs: [8]
      },
      /*{
        type: 'text',
        label: 'Gear Ratio *',
        data: null,
        apiKey: 'gear_ratio',
        validation: {},
        renderIfCategoryIs: [9, 10]
      },*/
      {
        type: 'text',
        label: 'Mileage',
        data: null,
        apiKey: 'mileage',
        validationType: 'number',
        validation: {required: 'Mileage is mandatory'},
        renderIfCategoryIs: [8]
      },
      {
        type: 'select',
        label: 'APU',
        data: [{ name: 'YES', id: 'YES' }, { name: 'NO', id: 'NO' }],
        apiKey: 'apu',
        validation: {required: 'APU is mandatory'},
        renderIfCategoryIs: [8]
      },
      {
        type: 'select',
        label: 'Inverter',
        data: [{ name: 'YES', id: true }, { name: 'NO', id: false }],
        apiKey: 'inverter',
        validation: {required: 'Inverter is mandatory'},
        renderIfCategoryIs: [8]
      },
      {
        type: 'select',
        label: 'Horse Power',
        data: [{}],
        apiKey: 'horsepower',
        validation: {required: 'Horse Power is mandatory'},
        renderIfCategoryIs: [8]
      },
      {
        type: 'select',
        label: 'Air Ride',
        data: [{ name: 'YES', id: 'YES' }, { name: 'NO', id: 'NO' }],
        apiKey: 'air_ride',
        validation: {required: 'Air Ride is mandatory'},
        renderIfCategoryIs: [9, 10]
      },
      {
        type: 'select',
        label: 'Scale',
        data: [{ name: 'YES', id: 'YES' }, { name: 'NO', id: 'NO' }],
        apiKey: 'scale',
        validation: {required: 'Scale is mandatory'},
        renderIfCategoryIs: [9, 10]
      },
      {
        type: 'select',
        label: 'Side Skirts',
        data: [{ name: 'YES', id: 'YES' }, { name: 'NO', id: 'NO' }],
        apiKey: 'side_skirt',
        validation: {required: 'Side Skirt is mandatory'},
        renderIfCategoryIs: [9, 10]
      },
      {
        type: 'select',
        label: 'Bed Count',
        data: [{name: 'Single', id:'Single'}, {name: 'Double', id: 'Double'}],
        apiKey: 'bed_count',
        validation: {required: 'Bed Count is mandatory'},
        renderIfCategoryIs: [8]
      },
      {
        type: 'select',
        label: 'Tire Rack',
        data: [{ name: 'YES', id: 'YES' }, { name: 'NO', id: 'NO' }],
        apiKey: 'tire_rack',
        validation: {required: 'Tire Rack is mandatory'},
        renderIfCategoryIs: [9, 10]
      },
    ],
    [
      {
        type: 'select',
        label: 'Outstanding Loan',
        data: [{ name: 'YES', id: 'YES' },{ name: 'NO', id: 'NO' },{ name: "I don't know", id: "I don't know" }],
        apiKey: 'outstanding_loan',
        validation: {},
        renderIfCategoryIs: [9, 10]
      },
      {
        type: 'select',
        label: 'Trailer Usage',
        data: [{ name: 'Local', id: 'Local' },{ name: 'Long Haul', id: 'Long Haul' },{ name: 'Both of them', id: 'Both of them' }],
        apiKey: 'trailer_usage',
        validation: {},
        renderIfCategoryIs: [9, 10]
      },
      {
        type: 'select',
        label: 'Major Repair',
        data: [{ name: 'YES', id: 'YES' },{ name: 'NO', id: 'NO' },{ name: "I don't know", id: "I don't know" }],
        apiKey: 'major_repairs',
        validation: {},
        renderIfCategoryIs: [9, 10]
      },
      {
        type: 'select',
        label: 'Purchases',
        data: [{ name: 'Dealership', id: 'Dealership' },{ name: 'Private Party', id: 'Private Party' },{ name: "I don't know", id: "I don't know"}],
        apiKey: 'purchase_source',
        validation: {},
        renderIfCategoryIs: [9, 10]
      },
      {
        type: 'select',
        label: 'GVWR',
        data: [{}],
        apiKey: 'gross_vehicle_weight_rating',
        validation: {},
        renderIfCategoryIs: ''
      },
      {
        type: 'select',
        label: 'Axle',
        data: [{}],
        apiKey: 'axle_ratio',
        validation: {},
        renderIfCategoryIs: ''
      },
      {
        type: 'select',
        label: 'Tire Tread Left',
        data: [{}],
        apiKey: 'tire_treads',
        validation: {},
        renderIfCategoryIs: ''
      },
      {
        type: 'select',
        label: 'Brake Type',
        data: [{}],
        apiKey: 'brake_type',
        validation: {},
        renderIfCategoryIs: [8]
      },
      /*{
        type: 'select',
        label: 'Collision',
        data: [{}],
        apiKey: 'collision',
        validation: {},
        renderIfCategoryIs: ''
      },*/
      {
        type: 'select',
        label: 'Collision Mitigation',
        data: [{}],
        apiKey: 'collision',
        validation: {},
        renderIfCategoryIs: [8]
      },
      {
        type: 'currency',
        label: 'Your Price',
        data: [{}],
        apiKey: 'vehicle_price',
        validation: {required: 'Your Price is mandatory'},
        renderIfCategoryIs: ''
      }
    ]
  ]);

  // State/Data forStep 4 - Upload Images
  const [imagesMap, setImagesMap] = useState([
    {
      is_primary: false
    },
    {
      is_primary: false
    },
    {
      is_primary: false
    },
    {
      is_primary: false
    },
    {
      is_primary: false
    },
    {
      is_primary: false
    },
    {
      is_primary: false
    },
    {
      is_primary: false
    },
    {
      is_primary: false
    },
    {
      is_primary: false
    },
    {
      is_primary: false
    },
    {
      is_primary: false
    }
  ]);
  /*{
      id: 2114,
      name: 'Screenshot 2023-04-14 at 10.57.30 AM',
      mime_type: 'image/png',
      document_key: 'IMAGE/Screenshot 2023-04-14 at 10.57.30 AM.png',
      description: 'IMAGE',
      attachment: 'https://smrdev.s3.us-west-1.amazonaws.com/IMAGE/Screenshot_2023-04-14_at_10.57.30_AM.png',
      thumbnail: null,
      is_primary: true
    }*/

  // State/Data forStep 5 - Upload Video
  const [videosMap, setVideosMap] = useState([null, null]);
  /*{
    "id": 2829,
    "name": "COMPRESSED-TWG_Explainer_FINAL-1",
    "mime_type": "video/mp4",
    "document_key": "VIDEO/COMPRESSED-TWG_Explainer_FINAL-1.mp4",
    "description": "VIDEO",
    "attachment": "https://smrdev.s3.us-west-1.amazonaws.com/VIDEO/COMPRESSED-TWG_Explainer_FINAL-1.mp4",
    "thumbnail": null,
    "is_primary": false,
    "listing_id": null
  }*/

  // State/Data forStep 6 - Description
  const [desc, setDesc] = useState('');

  // State/Data forStep 7 - Specification Sheet
  const [specificationSheetInputFileData, setSpecificationSheetInputFileData] = useState(null);
  const [specificationSheetMap, setSpecificationSheetMap] = useState([]);

  // State/Data forStep 8 - Repair History
  const [repairHistoryMap, setRepairHistoryMap] = useState([]);
  const [repairHistoryInputFileDataMap, setRepairHistoryInputFileDataMap] = useState([]);



  // State for Stepper
  const [activeStep, setActiveStep] = useState(0);

  const sampleMethod = () => {
    // Todo: Remove it just used it for prop handling
  }

  const getLocation = () => {
    if (!navigator.geolocation) {
      toast.toastInfo('Geolocation is not supported by your browser');
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log({position});
          setLongitude(position.coords.longitude);
          setLatitude(position.coords.latitude);
          setLocalStorageItem('SMR-longitude', position.coords.longitude);
          setLocalStorageItem('SMR-latitude', position.coords.latitude);
        },
        () => {
          toast.toastInfo('Unable to retrieve your location');
        }
      );
    }
  };

  useEffect(() => {
    if (
      getLocalStorageItem('SMR-longitude') &&
      getLocalStorageItem('SMR-latitude')
    ) {
      console.log("Position If");
      setLongitude(getLocalStorageItem('SMR-longitude'));
      setLatitude(getLocalStorageItem('SMR-latitude'));
      refetch({
        latitude: getLocalStorageItem('SMR-latitude'),
        longitude: getLocalStorageItem('SMR-longitude'),
      });
    } else {
      console.log("Position Else");
      getLocation();
    }
  }, []);

  useEffect(() => {
    if(locationData) {
      setAddressData(locationData.data);
    }
  }, [locationData]);

  // Template
  return (
    <>
      <div
        className='w-full outter-most-wrapper'
        style={{ backgroundImage: 'linear-gradient(to right, #0F5E91 50%, #ffffff 50%)'}}
      >
        <Box
          style={{
            maxWidth: '1700px',
          }}
          className={`${isMedium ? '!px-4' : '!px-[100px]'} m-auto`}
        >
          <div className='ProductSellModule main-wrapper flex' style={{ height: 'calc(100vh - 100px)' }}>
            <div className='steps-wrapper w-[330px] h-full overflow-y-auto'>
              <VerticalLinearStepper activeStep = {activeStep} setActiveStep = {setActiveStep} sampleMethod = {sampleMethod}/>
            </div>
            <div className='content-wrapper bg-white w-5/6 h-full overflow-y-auto'>
              <div className='content'>
                {(() => {
                  switch (activeStep) {
                    case 0:
                      return <TruckCategory truckList={truckList} setTruckList={setTruckList} activeStep={activeStep}
                                            setActiveStep={setActiveStep} category={category} sub_category={sub_category}
                                            setCategory={setCategory} setSub_category={setSub_category} />;
                      break;
                    case 1:
                      return <VehicleID activeStep={activeStep} setActiveStep={setActiveStep}
                                        vehicle={vehicle} setVehicle={setVehicle}
                      />;
                      break;
                    case 2:
                      return <BasicInformationPS category={category} sub_category={sub_category}
                                                 basicInfoFormElements={basicInfoFormElements}
                                                 setBasicInfoFormElements={setBasicInfoFormElements}
                                                 vehicle={vehicle} setVehicle={setVehicle}
                                                 activeStep={activeStep} setActiveStep={setActiveStep} />;
                      break;
                    case 3:
                      return <DescriptionPS desc={desc} setDesc={setDesc}
                                            activeStep={activeStep} setActiveStep={setActiveStep} />;
                      break;
                    case 4:
                      return <UploadImagesPS imagesMap={imagesMap} setImagesMap={setImagesMap} activeStep={activeStep}
                                             setActiveStep={setActiveStep} />;
                      break;
                    case 5:
                      return <UploadVideoPS activeStep={activeStep} setActiveStep={setActiveStep}
                                            videosMap={videosMap} setVideosMap={setVideosMap}/>;
                      break;
                    case 6:
                      return <SpecificationSheetPS specificationSheetMap={specificationSheetMap}
                                                   setSpecificationSheetMap={setSpecificationSheetMap}
                                                   activeStep={activeStep} setActiveStep={setActiveStep}
                                                   specificationSheetInputFileData={specificationSheetInputFileData}
                                                   setSpecificationSheetInputFileData={setSpecificationSheetInputFileData}
                      />;
                      break;
                    case 7:
                      return <RepairHistoryPS isLoading={isLoading}
                                              repairHistoryMap={repairHistoryMap}
                                              setRepairHistoryMap={setRepairHistoryMap} activeStep={activeStep}
                                              setActiveStep={setActiveStep}
                                              repairHistoryInputFileDataMap={repairHistoryInputFileDataMap}
                                              setRepairHistoryInputFileDataMap={setRepairHistoryInputFileDataMap}
                                              handleFinalSubmit={handleFinalSubmit} />;
                      break;
                    default:
                      return <TruckCategory truckList={truckList} setTruckList={setTruckList} activeStep={activeStep}
                                            setActiveStep={setActiveStep} />;
                  }
                })()}
              </div>
            </div>
          </div>
        </Box>
      </div>
      <APIResponceMsgModal
        open={openResponse}
        setClose={setOpenResponse}
        modelData={modelData}
      />

    </>
  )
};


export {
  ProductSell
};