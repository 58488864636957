 
import {Box, Button, Grid, MenuItem, Select, Tooltip, Typography,useMediaQuery, useTheme} from "@mui/material"
import { CloseOutlined } from '@mui/icons-material'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { getData } from 'utils/localStorage'
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { Location } from './Location'
import { Profile } from './Profile'
import { getLocalStorageItem, localStorageKeys } from 'hooks'
import { FeedbackForm } from 'pages/LandingPage/FeedbackForm'
import { useState } from 'react'
import { nanoid } from "nanoid"
import Dropdown from "react-multilevel-dropdown"
import { routes } from "routes/RouteConstants"
import { Finance } from "pages/LandingPage/Finance"


const TiktokIcon = ({ color = "#4d4d4d" }) => {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="30px"
      height="30px"
    >
      <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
    </svg>
  );
};

export const MobileNav = ({handleClose}) => {
    
  const isAuth=getData(localStorageKeys.AUTH_TOKEN)
  const navigate=useNavigate()
  const theme = useTheme();
  const userData = getLocalStorageItem('USER_DETAILS');
  const [openForm,setOpenForm]= useState(false)
  const [openFinance, setOpenFinance] = useState(false);
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const handleOpenReviewForm=()=>{
    setOpenForm(true)
  }
  const handleFinance = (e) => {
    e.preventDefault();
    if (userData?.finance_count > 0) {
      navigate('/financing-listing');
      handleClose();
    } else {
      setOpenFinance(true);
      handleClose();
    }
  };
  return (
    <>
      <Finance open={openFinance} setOpen={setOpenFinance} />
      <Grid container className="bg-white  gap-0 relative" direction="column">
        <Grid item className="bg-secondary-50">
          <CloseOutlined
            onClick={handleClose}
            className="text-white abosolute top-5 left-5"
          />
        </Grid>
        <Grid item container className="bg-secondary-50 px-10 pb-10">
          <Grid
            container
            className="pt-5"
            justifyContent="space-between"
            alignItems="center"
          >
            {isAuth ? <Profile text="white" /> : <></>}
          </Grid>
          <Grid item container className="gap-5  pt-10 my-3" direction="column">
            <Grid item>
              <Link
                to={routes.REDIRECTOR}
                onClick={handleClose}
                className="text-white border-none no-underline"
              >
                HOME
              </Link>
            </Grid>
            <Grid item className="mr-2">
              {/* <Link onClick={handleClick} className='textunderline hover-underline-animation'>INVENTORY</Link> */}

              <Dropdown
                title="INVENTORY"
                position="right"
                style={{ background: 'none', marginLeft: '-5px' }} // Added marginRight to the title
                className="text-white !font-[500]  border-none textunderline  font-Inter mr-[10px]"
              >
                <Dropdown.Item>
                  Commercial Trucks
                  <Dropdown.Submenu position="right">
                    <Tooltip
                      title="Search for Sleeper Cab"
                      placement="right-start"
                    >
                      <Dropdown.Item
                        // title="Search for sleeper Cab"

                        onClick={() => {
                          navigate('/inventory', {
                            state: {
                              forFilter: [
                                {
                                  key: nanoid(),
                                  label: 'Commercial Truck',
                                  filter: 'category',
                                },
                                {
                                  key: nanoid(),
                                  label: 'Sleeper Cab',
                                  filter: 'sub_category',
                                },
                              ],
                            },
                          });

                          handleClose();
                        }}
                      >
                        Sleeper Cab
                      </Dropdown.Item>
                    </Tooltip>
                    <Tooltip title="Search for Day Cab" placement="right-start">
                      <Dropdown.Item
                        onClick={() => {
                          navigate('/inventory', {
                            state: {
                              forFilter: [
                                {
                                  key: nanoid(),
                                  label: 'Commercial Truck',
                                  filter: 'category',
                                },
                                {
                                  key: nanoid(),
                                  label: 'Day Cab',
                                  filter: 'sub_category',
                                },
                              ],
                            },
                          });
                          handleClose();
                        }}
                      >
                        Day Cab
                      </Dropdown.Item>
                    </Tooltip>
                    <Tooltip
                      title="Search for Dump Trucks"
                      placement="right-start"
                    >
                      <Dropdown.Item
                        onClick={() => {
                          navigate('/inventory', {
                            state: {
                              forFilter: [
                                {
                                  key: nanoid(),
                                  label: 'Commercial Truck',
                                  filter: 'category',
                                },
                                {
                                  key: nanoid(),
                                  label: 'Dump Trucks',
                                  filter: 'sub_category',
                                },
                              ],
                            },
                          });
                          handleClose();
                        }}
                      >
                        Dump Trucks
                      </Dropdown.Item>
                    </Tooltip>
                    <Tooltip
                      title="Search for Box Trucks"
                      placement="right-start"
                    >
                      <Dropdown.Item
                        onClick={() => {
                          navigate('/inventory', {
                            state: {
                              forFilter: [
                                {
                                  key: nanoid(),
                                  label: 'Commercial Truck',
                                  filter: 'category',
                                },
                                {
                                  key: nanoid(),
                                  label: 'Box Trucks',
                                  filter: 'sub_category',
                                },
                              ],
                            },
                          });
                          handleClose();
                        }}
                      >
                        Box Trucks
                      </Dropdown.Item>
                    </Tooltip>
                  </Dropdown.Submenu>
                </Dropdown.Item>
                <Dropdown.Item>
                  Trailers
                  <Dropdown.Submenu position="right">
                    <Tooltip
                      title="Search for Reefer Trailer"
                      placement="right-start"
                    >
                      <Dropdown.Item
                        onClick={() => {
                          navigate('/inventory', {
                            state: {
                              forFilter: [
                                {
                                  key: nanoid(),
                                  label: 'Trailer',
                                  filter: 'category',
                                },
                                {
                                  key: nanoid(),
                                  label: 'Reefer Trailers',
                                  filter: 'sub_category',
                                },
                              ],
                            },
                          });
                          handleClose();
                        }}
                      >
                        Reefer Trailer
                      </Dropdown.Item>
                    </Tooltip>
                  </Dropdown.Submenu>
                </Dropdown.Item>
                <Dropdown.Item>
                  Van
                  <Dropdown.Submenu position="right">
                    <Tooltip
                      title="Search for Dry Vans"
                      placement="right-start"
                    >
                      <Dropdown.Item
                        onClick={() => {
                          navigate('/inventory', {
                            state: {
                              forFilter: [
                                {
                                  key: nanoid(),
                                  label: 'Dry Van',
                                  filter: 'category',
                                },
                                {
                                  key: nanoid(),
                                  label: 'Dry Vans',
                                  filter: 'sub_category',
                                },
                              ],
                            },
                          });
                          handleClose();
                        }}
                      >
                        Dry Vans
                      </Dropdown.Item>
                    </Tooltip>
                  </Dropdown.Submenu>
                </Dropdown.Item>
              </Dropdown>
            </Grid>
            <Grid item>
              {/* <Link
                to="#"
                onClick={(e) => handleFinance(e)}
                className="text-white border-none no-underline"
              >
                FINANCEee
              </Link> */}
              <a
                href="https://rent.sellmyrig.com/" target="_blank"
                // onClick={(e) => handleFinance(e)}
                className="text-white border-none no-underline"
              >
                RENTALS
              </a>
            </Grid>
            <Grid item>
              <Link
                to="/about-us"
                onClick={handleClose}
                className="text-white  !font-Inter border-none no-underline"
              >
                ABOUT US
              </Link>
            </Grid>

            <Grid item>
              <Link
                to="/contact-us"
                onClick={handleClose}
                className="text-white  !font-Inter border-none no-underline"
              >
                CONTACT US
              </Link>
            </Grid>
          </Grid>

          {/* <Dropdown
          title="INVENTORY"
          position="right"
          style={{ background: 'none' }}
          className="text-black !font-[500]  border-none textunderline hover-underline-animation font-Inter"
        >
          <Dropdown.Item>
            Commercial Trucks
            <Dropdown.Submenu position="right">
              <Tooltip title="Search for Sleeper Cab" placement="right-start">
                <Dropdown.Item
                  // title="Search for sleeper Cab"

                  onClick={() => {
                    navigate('/inventory', {
                      state: {
                        forFilter: [
                          {
                            key: nanoid(),
                            label: 'Commercial Truck',
                            filter: 'category',
                          },
                          {
                            key: nanoid(),
                            label: 'Sleeper Cab',
                            filter: 'sub_category',
                          },
                        ],
                      },
                    });
                  }}
                >
                  Sleeper Cab
                </Dropdown.Item>
              </Tooltip>
              <Tooltip title="Search for Day Cab" placement="right-start">
                <Dropdown.Item
                  onClick={() => {
                    navigate('/inventory', {
                      state: {
                        forFilter: [
                          {
                            key: nanoid(),
                            label: 'Commercial Truck',
                            filter: 'category',
                          },
                          {
                            key: nanoid(),
                            label: 'Day Cab',
                            filter: 'sub_category',
                          },
                        ],
                      },
                    });
                  }}
                >
                  Day Cab
                </Dropdown.Item>
              </Tooltip>
              <Tooltip title="Search for Dump Trucks" placement="right-start">
                <Dropdown.Item
                  onClick={() => {
                    navigate('/inventory', {
                      state: {
                        forFilter: [
                          {
                            key: nanoid(),
                            label: 'Commercial Truck',
                            filter: 'category',
                          },
                          {
                            key: nanoid(),
                            label: 'Dump Trucks',
                            filter: 'sub_category',
                          },
                        ],
                      },
                    });
                  }}
                >
                  Dump Trucks
                </Dropdown.Item>
              </Tooltip>
              <Tooltip title="Search for Box Trucks" placement="right-start">
                <Dropdown.Item
                  onClick={() => {
                    navigate('/inventory', {
                      state: {
                        forFilter: [
                          {
                            key: nanoid(),
                            label: 'Commercial Truck',
                            filter: 'category',
                          },
                          {
                            key: nanoid(),
                            label: 'Box Trucks',
                            filter: 'sub_category',
                          },
                        ],
                      },
                    });
                  }}
                >
                  Box Trucks
                </Dropdown.Item>
              </Tooltip>
            </Dropdown.Submenu>
          </Dropdown.Item>
          <Dropdown.Item>
            Trailers
            <Dropdown.Submenu position="right">
              <Tooltip
                title="Search for Reefer Trailer"
                placement="right-start"
              >
                <Dropdown.Item
                  onClick={() => {
                    navigate('/inventory', {
                      state: {
                        forFilter: [
                          {
                            key: nanoid(),
                            label: 'Trailer',
                            filter: 'category',
                          },
                          {
                            key: nanoid(),
                            label: 'Reefer Trailers',
                            filter: 'sub_category',
                          },
                        ],
                      },
                    });
                  }}
                >
                  Reefer Trailer
                </Dropdown.Item>
              </Tooltip>
            </Dropdown.Submenu>
          </Dropdown.Item>
          <Dropdown.Item>
            Van
            <Dropdown.Submenu position="right">
              <Tooltip title="Search for Dry Vans" placement="right-start">
                <Dropdown.Item
                  onClick={() => {
                    navigate('/inventory', {
                      state: {
                        forFilter: [
                          {
                            key: nanoid(),
                            label: 'Dry Van',
                            filter: 'category',
                          },
                          {
                            key: nanoid(),
                            label: 'Dry Vans',
                            filter: 'sub_category',
                          },
                        ],
                      },
                    });
                  }}
                >
                  Dry Vans
                </Dropdown.Item>
              </Tooltip>
            </Dropdown.Submenu>
          </Dropdown.Item>
        </Dropdown> */}
        </Grid>
        {!isAuth ? (
          <Grid item container className="bg-white py-5 gap-3 3">
            <Grid item container>
              <Button
                onClick={() => {
                  navigate('/signup');
                }}
                className=" !bg-secondary-50 !text-white w-[90%] !m-auto bg py-3"
              >
                Sign Up
              </Button>
            </Grid>
            <Grid item container>
              <Button
                onClick={() => {
                  navigate('/login');
                }}
                className="w-[90%] !m-auto border-2 border-solid border-secondary-50 py-3"
              >
                Login
              </Button>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
        {!isMedium == false && (
          <Box
            className="py-2 flex !w-auto mr-10 text-center justify-center"
            spacing={2}
          >
            <a href="https://www.tiktok.com/@sellmyrig?_t=8hTRArxx67f&_r=1" target="_blank"><TiktokIcon className="px-2 text-[32px] text-gray-70" /></a>
            <a href="https://www.facebook.com/profile.php?id=100092651114283&mibextid=LQQJ4d" target="_blank"><FacebookIcon className="px-2 text-[32px] text-gray-70" /></a>
             {/* <TwitterIcon className=" px-2 text-[32px] text-gray-70" /> */}
            <a href="https://instagram.com/sellmyrigllc?igshid=MzMyNGUyNmU2YQ==" target="_blank"><InstagramIcon className=" px-2 text-[32px] text-gray-70" /></a>
            <a href="https://www.linkedin.com/company/sell-my-rig/" target="_blank"><LinkedInIcon className="px-2 text-[32px] text-gray-70" /></a>
          </Box>
        )}
        <FeedbackForm openForm={openForm} setOpenForm={setOpenForm} />
      </Grid>
    </>
  );
}
