import { Grid, Tooltip } from '@mui/material';
import { FeedbackForm } from 'pages/LandingPage/FeedbackForm';
import { Finance } from 'pages/LandingPage/Finance';
import React from 'react';
import { useState } from 'react';
import Dropdown from 'react-multilevel-dropdown';
import { Link, useNavigate } from 'react-router-dom';
import { routes } from 'routes/RouteConstants';
import { nanoid } from 'nanoid';
import { getLocalStorageItem } from 'hooks';

export const SecondaryHeader = () => {
  const [openForm, setOpenForm] = useState(false);
  const [openFinance, setOpenFinance] = useState(false);
  const userData = getLocalStorageItem('USER_DETAILS');
  const navigate = useNavigate();

  const handleFinance = (e) => {
    e.preventDefault();
    if (userData?.finance_count > 0) {
      navigate('/financing-listing');
    } else {
      setOpenFinance(true);
    }
  };

  return (
    <>
         <Finance open={openFinance} setOpen={setOpenFinance} />
      <Grid item>
        <Link
          to={routes.REDIRECTOR}
          className="textunderline hover-underline-animation !font-Inter"
        >
          HOME
        </Link>
      </Grid>
      <Grid item>
        {/* <Link onClick={handleClick} className='textunderline hover-underline-animation'>INVENTORY</Link> */}

        <Dropdown
          title="INVENTORY"
          position="right"
          style={{ background: 'none' }}
          className="text-black !font-[500]  border-none textunderline hover-underline-animation font-Inter"
        >
          <Dropdown.Item>
            Commercial Trucks
            <Dropdown.Submenu position="right">
              <Tooltip title="Search for Sleeper Cab" placement="right-start">
                <Dropdown.Item
                  // title="Search for sleeper Cab"

                  onClick={() => {
                    navigate('/inventory', {
                      state: {
                        forFilter: [
                          {
                            key: nanoid(),
                            label: 'Commercial Truck',
                            filter: 'category',
                          },
                          {
                            key: nanoid(),
                            label: 'Sleeper Cab',
                            filter: 'sub_category',
                          },
                        ],
                      },
                    });
                  }}
                >
                  Sleeper Cab
                </Dropdown.Item>
              </Tooltip>
              <Tooltip title="Search for Day Cab" placement="right-start">
                <Dropdown.Item
                  onClick={() => {
                    navigate('/inventory', {
                      state: {
                        forFilter: [
                          {
                            key: nanoid(),
                            label: 'Commercial Truck',
                            filter: 'category',
                          },
                          {
                            key: nanoid(),
                            label: 'Day Cab',
                            filter: 'sub_category',
                          },
                        ],
                      },
                    });
                  }}
                >
                  Day Cab
                </Dropdown.Item>
              </Tooltip>
              <Tooltip title="Search for Dump Trucks" placement="right-start">
                <Dropdown.Item
                  onClick={() => {
                    navigate('/inventory', {
                      state: {
                        forFilter: [
                          {
                            key: nanoid(),
                            label: 'Commercial Truck',
                            filter: 'category',
                          },
                          {
                            key: nanoid(),
                            label: 'Dump Trucks',
                            filter: 'sub_category',
                          },
                        ],
                      },
                    });
                  }}
                >
                  Dump Trucks
                </Dropdown.Item>
              </Tooltip>
              <Tooltip title="Search for Box Trucks" placement="right-start">
                <Dropdown.Item
                  onClick={() => {
                    navigate('/inventory', {
                      state: {
                        forFilter: [
                          {
                            key: nanoid(),
                            label: 'Commercial Truck',
                            filter: 'category',
                          },
                          {
                            key: nanoid(),
                            label: 'Box Trucks',
                            filter: 'sub_category',
                          },
                        ],
                      },
                    });
                  }}
                >
                  Box Trucks
                </Dropdown.Item>
              </Tooltip>
            </Dropdown.Submenu>
          </Dropdown.Item>
          <Dropdown.Item>
            Trailers
            <Dropdown.Submenu position="right">
              <Tooltip
                title="Search for Reefer Trailer"
                placement="right-start"
              >
                <Dropdown.Item
                  onClick={() => {
                    navigate('/inventory', {
                      state: {
                        forFilter: [
                          {
                            key: nanoid(),
                            label: 'Trailer',
                            filter: 'category',
                          },
                          {
                            key: nanoid(),
                            label: 'Reefer Trailers',
                            filter: 'sub_category',
                          },
                        ],
                      },
                    });
                  }}
                >
                  Reefer Trailer
                </Dropdown.Item>
              </Tooltip>
            </Dropdown.Submenu>
          </Dropdown.Item>
          <Dropdown.Item>
            Van
            <Dropdown.Submenu position="right">
              <Tooltip title="Search for Dry Vans" placement="right-start">
                <Dropdown.Item
                  onClick={() => {
                    navigate('/inventory', {
                      state: {
                        forFilter: [
                          {
                            key: nanoid(),
                            label: 'Dry Van',
                            filter: 'category',
                          },
                          {
                            key: nanoid(),
                            label: 'Dry Vans',
                            filter: 'sub_category',
                          },
                        ],
                      },
                    });
                  }}
                >
                  Dry Vans
                </Dropdown.Item>
              </Tooltip>
            </Dropdown.Submenu>
          </Dropdown.Item>
        </Dropdown>
      </Grid>
      <Grid item>
        {/* <Link
          to="#"
          onClick={(e) => handleFinance(e)}
          className="textunderline hover-underline-animation !font-Inter"
        >
          FINANCE
        </Link> */}
        <a
        href="https://rent.sellmyrig.com/" target="_blank"
          //  onClick={(e) => handleFinance(e)}
           className="textunderline hover-underline-animation !font-Inter"
           >
            RENTALS
         </a>
      </Grid>

      <Grid item>
        <Link
          to="/about-us"
          className="textunderline hover-underline-animation !font-Inter"
        >
          ABOUT US
        </Link>
      </Grid>

      <Grid item>
        <Link
          to="/contact-us"
          className="textunderline hover-underline-animation !font-Inter"
        >
          CONTACT US
        </Link>
      </Grid>
      <FeedbackForm openForm={openForm} setOpenForm={setOpenForm} />
    
    </>
  );
};
